// 最外层盒子样式
%out-box {
  //   width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以30；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.intangible-container {
  @extend %out-box;
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");

  .intangible-content {
    @extend %inside-box;
    padding-top: (90rem / 100);
    padding-bottom: (160rem / 100);

    // 顶部
    .intangible-switch-type {
      display: flex;
      margin-bottom: (12rem / 100);

      &-title {
        white-space: nowrap;
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #761f1e;
        line-height: (32rem / 100);
        margin-right: (10rem / 100);
      }

      &-content {
        display: flex;
        flex-wrap: wrap;

        &-li {
          padding: 0.06rem 0.24rem;
          margin: 0 (30rem / 100) (12rem / 100) 0;
          background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li.png")
            no-repeat;
          background-size: 100% 100%;
          text-align: center;
          font-size: (16rem / 100);
          font-family: PingFangSC-Regular, PingFang SC;
          color: #273143;
          cursor: pointer;
        }

        &-li-active {
          background: url("https://image.bookgo.com.cn/webculture/jm/activity_type_li.png")
            no-repeat;
          background-size: 100% 100%;
          color: #ffffff;
          font-weight: 500;
        }
      }
    }

    // 最新·最热
    .intangible-content-hot-new {
      display: flex;
      align-items: center;
      margin-top: (12rem / 100);
      margin-bottom: (35rem / 100);

      &-icon {
        width: (14rem / 100);
        height: (30rem / 100);
      }

      .intangible-content-new-mid-hot {
        width: (8rem / 100);
        height: (8rem / 100);
        background: #cd9e56;
        border-radius: 50%;
      }

      &-title {
        width: (40rem / 100);
        margin: 0 (12rem / 100) 0 (8rem / 100);
        position: relative;
        text-align: center;
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        color: #273143;
        line-height: (16rem / 100);
        cursor: pointer;
      }

      &-title1 {
        margin: 0 (8rem / 100) 0 (12rem / 100);
      }

      &-title-bg {
        width: (40rem / 100);
        height: (8rem / 100);
        background: rgba($color: #cd9e56, $alpha: 0.5);
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
}
